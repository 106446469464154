<template>
  <path id="Tracé_573" data-name="Tracé 573" d="M1.281,64A1.281,1.281,0,0,1,0,62.719V34.952a1.127,1.127,0,0,1,.05-.245L.024,34.7,6.965,0,9.477.5,2.842,33.672h52.4L48.611.5,51.122,0l6.942,34.7-.026.005a1.245,1.245,0,0,1,.05.245V62.719A1.281,1.281,0,0,1,56.807,64Zm1.28-2.561H55.527V36.233H2.561Zm13.992-10.31a1.28,1.28,0,0,1-1.281-1.279V44.3a1.28,1.28,0,0,1,1.281-1.28H41.537a1.279,1.279,0,0,1,1.28,1.28v5.554a1.279,1.279,0,0,1-1.28,1.279Zm1.279-2.56H40.256V45.576H17.832ZM48.585,31.71V26.045H9.5V31.71H6.94V24.764a1.281,1.281,0,0,1,1.281-1.281H49.865a1.281,1.281,0,0,1,1.281,1.281V31.71Zm-2.961-9.856v-6H12.463v6H9.9V14.577A1.281,1.281,0,0,1,11.182,13.3H46.9a1.28,1.28,0,0,1,1.28,1.281v7.276ZM42.892,11.166V5.67h-27.7v5.5H12.632V4.389a1.28,1.28,0,0,1,1.28-1.281H44.173a1.281,1.281,0,0,1,1.281,1.281v6.777Z" transform="translate(0.563 0.589)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
name: "IconGed"
}
</script>

<style scoped>

</style>